// Team Members
import Advisor1 from '../../../images/team/advisor1.svg';
import Advisor2 from '../../../images/team/advisor2.svg';
import Member3 from '../../../images/team/ux-specialist.jpg';

const Members = [
  {
    picture: Member3,
    name: 'Ezequiel Correa Luna',
    roleText: 'member4.role',
    linkedinURL: 'https://www.linkedin.com/in/ezequiel-correa-luna-44766950',
  },
  {
    picture: Advisor1,
    name: 'Santiago del Carril',
    roleText: 'advisor1.role',
    linkedinURL: 'https://www.linkedin.com/in/santiago-del-carril-07925084/',
  },
  {
    picture: Advisor2,
    name: 'Martín Arroyo',
    roleText: 'advisor2.role',
    linkedinURL: 'https://www.linkedin.com/in/martinarroyo01/',
  }
];

export default Members;
